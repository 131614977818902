import { createSlice } from '@reduxjs/toolkit';
import { api } from '../services/api';
import { EXCAVATOR } from '../constants';
import { hasPrivilege } from '../utils/privilege.utils';

const initialState = {
	defaultEquipment: {
		id: '',
		displayName: '',
		name: '',
		odometer: 0,
		odometerUnit: 'km', // hours
		categoryName: '',
		type: '',
		subType: '',
	},
	user: {
		id: null,
		name: null,
		language: null,
		hasBirtday: false,
		privileges: [],
	},
	hasStimpilklukka: false,
	hasVerklinur: false,
	timeClock: {
		isLoading: false,
		isActive: false,
		timeClockedIn: null,
		timeClockedOut: null,
		durationMinutes: null,
		timeRecordId: null,
		equipmentClockedIn: {
			id: '',
			displayName: '',
			name: '',
			odometer: 0,
			odometerUnit: 'km', // hours
			categoryName: '',
			type: '',
			subType: '',
		},
	},
	isTimeRecordDialogOpen: false,
	selectedTimeRecordId: null,
};

export const employeeSlice = createSlice({
	name: 'employee',
	initialState,
	reducers: {
		// Time Clock
		openTimeRecordDialog: (state, action) => {
			state.isTimeRecordDialogOpen = true;
			state.selectedTimeRecordId = action.payload;
		},
		closeTimeRecordDialog: (state) => {
			state.isTimeRecordDialogOpen = false;
			state.selectedTimeRecordId = null;
		},
		setTimeClockIsLoading: (state, action) => {
			state.timeClock.isLoading = action.payload;
        },
		setTimeClockedIn: (state, action) => {
			state.timeClock.timeClockedIn = action.payload;
			state.timeClock.isActive = true;
		},
		setTimeClockedOut: (state, action) => {
			state.timeClock.timeClockedOut = action.payload;
			state.timeClock.isActive = false;
		},
		setEquipmentClockedIn: (state, action) => {
			const equipment = action.payload;
			// Equipment from the API
			state.timeClock.equipmentClockedIn.id = equipment?.id || '';
			state.timeClock.equipmentClockedIn.displayName =
				equipment?.displayName || '';
			state.timeClock.equipmentClockedIn.name = equipment?.name || '';
			state.timeClock.equipmentClockedIn.name = equipment?.name || '';
			state.timeClock.equipmentClockedIn.odometer =
				equipment?.odometer || '';
			state.timeClock.equipmentClockedIn.type = equipment?.type || '';
			state.timeClock.equipmentClockedIn.subtype =
				equipment?.subtype || '';

			state.timeClock.equipmentClockedIn.odometerUnit =
				(equipment?.equipmentType?.category === EXCAVATOR
					? 'hours'
					: 'km') || '';
			state.timeClock.equipmentClockedIn.categoryName =
				equipment?.equipmentType?.name || '';
		},
		setUser: (state, action) => {
			state.user = action.payload;
			state.hasStimpilklukka = hasPrivilege(action.payload, 'stimpilklukka');
			state.hasVerklinur = hasPrivilege(action.payload, 'verklinur');
		},
		clearUser: (state) => {
			state.user = { ...initialState.user };
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			api.endpoints.getClockInStatus.matchPending,
			(state, action) => {
				state.timeClock.isLoading = true;
			},
		);
		builder.addMatcher(
			api.endpoints.getClockInStatus.matchFulfilled,
			(state, action) => {
				state.timeClock.isLoading = false;

				const timeRecord = action.payload;
				if (!timeRecord) {
					state.timeClock.isActive = false;
					return;
				}
				state.timeClock.isActive = timeRecord?.start && !timeRecord?.end;
				state.timeClock.timeRecordId = timeRecord?.id || null;
				state.timeClock.timeClockedIn = timeRecord?.start || null;
				state.timeClock.timeClockedOut = timeRecord?.end || null;
				state.timeClock.durationMinutes =
					timeRecord?.durationMinutes || null;
			},
		);
		builder.addMatcher(
			api.endpoints.getDefaultEquipment.matchPending,
			(state, action) => {
				state.timeClock.isLoading = true;
			},
		);
		builder.addMatcher(
			api.endpoints.getDefaultEquipment.matchFulfilled,
			(state, action) => {
				state.timeClock.isLoading = false;
				const [equipment] = action.payload;

				if (equipment) {
					state.defaultEquipment.id = equipment?.id || '';
					state.defaultEquipment.displayName = equipment?.displayName || '';
					state.defaultEquipment.name = equipment?.name || '';
					state.defaultEquipment.odometer = equipment?.odometer || '';
					state.defaultEquipment.type = equipment?.type || '';
					state.defaultEquipment.subtype = equipment?.subType || '';

					state.defaultEquipment.odometerUnit =
						(equipment?.equipmentType?.category === EXCAVATOR
							? 'hours'
							: 'km') || '';
					state.defaultEquipment.categoryName = equipment?.equipmentType?.name || '';
				} else {
					state.defaultEquipment = {
						id: '',
						displayName: '',
						name: '',
						odometer: '',
						type: '',
						subType: '',
						odometerUnit: '',
						categoryName: '',
					};
				}
			},
		);
	},
});

export const employeeActions = employeeSlice.actions;
