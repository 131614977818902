import React, { useState, forwardRef, useEffect } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Dialog,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Box,
    CircularProgress,
    Typography,
    Paper,
    Chip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';

import { useUpdateCommentMutation } from '../../services/api';
import {
    getDisplayDate,
    getTypeLabel,
    getTimeRangeWithDuration,
    getStatusLabel,
    getStatusColor
} from '../../utils/timerecord.utils';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} timeout={100} {...props} />;
});

export default function TimeRecordDialog({ timeRecord, isOpen, onClose }) {
    const { t } = useTranslation();
    const user = useSelector((state) => state.employee.user);
    const [isMounted, setIsMounted] = useState(false);
    const [comment, setComment] = useState(timeRecord?.message || '');

    const [updateComment, { isLoading }] = useUpdateCommentMutation();

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        setComment(timeRecord?.message || '');
    }, [timeRecord]);

    if (!isMounted) return null;

    const handleOnChange = (event) => {
        setComment(event.target.value)
    };

    const handleSaveComment = async () => {
        if (!timeRecord?.id || timeRecord?.message === comment) return; 

        try {
            const id = timeRecord.id;
            await updateComment({ id, comment });
            toast.success(t('Comment added'));
            onClose();
        } catch (err) {
            console.error("Error while updating comment", err);
            toast.error(t('Comment added error'));
        }
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close-back"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {`${t('Time Clock')}${timeRecord?.start ? ` - ${dayjs(timeRecord.start).format('ddd DD MMM')}` : ''}`}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', p: 4, backgroundColor: '#f5f5f5', gap: 6 }}>
                <Paper elevation={2} sx={{ p: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h6">{user?.name || ''}</Typography>
                        <Typography variant="subtitle1">{getTimeRangeWithDuration(timeRecord)}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        <Chip size="small" label={getTypeLabel(timeRecord)} />
                        <Chip
                            label={getStatusLabel(timeRecord?.status)}
                            color={getStatusColor(timeRecord?.status)}
                            size="small"
                            variant="outlined"
                        />
                    </Box>
                </Paper>

                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2 }}>
                    <Typography variant="h4"  align="left" sx={{ fontWeight: 'medium' }}>
                        {timeRecord?.start && getDisplayDate(timeRecord.start)}
                    </Typography>
                    <TextField
                        sx={{ backgroundColor: 'white' }}
                        fullWidth
                        id="timerecord-comment"
                        label={t('comment')}
                        value={comment}
                        onChange={handleOnChange}
                        multiline
                        rows={3}
                        placeholder={`${t('comment')}...`}
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{ px: 4, mb: 4 }}>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSaveComment}
                    disabled={isLoading || !timeRecord?.id || (timeRecord?.message === comment || (timeRecord?.message === null && !comment))}
                >
                    {isLoading ? (
                        <CircularProgress color="inherit" size={24} />
                    ): (
                        t('Confirm')
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    )

};
