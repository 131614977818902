import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
	Container,
	Button,
	Box,
	Typography,
	Skeleton,
	Grid,
} from '@mui/material';

import {
	LoadingButton,
	TimeRecordList,
	CurrentTime,
	ReportAbsentDialog,
	TimeRecordDialog,
	PayPeriodSummaryTable,
} from '../../components';
import {
	useClockInMutation,
	useClockOutMutation,
	useGetTimeRecordsQuery,
	useGetWorklinesQuery
} from '../../services/api';
import { employeeActions } from '../../App/employeeSlice';

const ClockInButton = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { user, hasVerklinur } = useSelector((state) => state.employee);
	const [clockIn, { isLoading: isClockingIn }] = useClockInMutation();

	const {
		data: workLines = [],
	} = useGetWorklinesQuery(user.id, {
		skip: !user.id || !hasVerklinur,
	});

	const handleClockIn = () => {
		const timestamp = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

		dispatch(employeeActions.setTimeClockIsLoading(true));
		clockIn({
			employeeId: user.id,
			timestamp: timestamp,
		})
			.unwrap()
			.then(() => {
				dispatch(employeeActions.setTimeClockedIn(timestamp));
				toast.success(
					`${t('Clocked in')}! ${dayjs(timestamp).format(
						'ddd DD MMM HH:mm',
					)}`,
				);
				const hasActiveWorkLines = Array.isArray(workLines) && workLines.length > 0 && workLines.some((line) => line?.isFinished === false);

				if (hasActiveWorkLines) navigate('/work'); // Navigate to /work if active work lines 
				else navigate('/newline'); // Navigate to /newline if no active work lines exist
			})
			.catch((error) => {
				toast.error(`Failed to clock in. Reason: ${error.message}`);
			})
			.finally(() => {
				dispatch(employeeActions.setTimeClockIsLoading(false));
            });
	};

	return (
		<LoadingButton
			size="large"
			variant="contained"
			fullWidth
			onClick={handleClockIn}
			loading={isClockingIn}
			disabled={isClockingIn}
		>
			{t('Clock in')}
		</LoadingButton>
	);
};

const ClockOutButton = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const user = useSelector((state) => state.employee.user);
	const { timeRecordId } = useSelector((state) => state.employee.timeClock);
	const [clockOut, { isLoading: isClockingOut }] = useClockOutMutation();

	const handleClockOut = () => {
		const timestamp = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
		clockOut({
			timeRecordId,
			employeeId: user.id,
			timestamp: timestamp,
		})
			.unwrap()
			.then(() => {
				dispatch(employeeActions.setTimeClockedOut(timestamp));
				toast.success(
					`${t('Clocked out')}! ${dayjs(timestamp).format(
						'ddd DD MMM HH:mm',
					)}`,
				);
			})
			.catch((error) => {
				toast.error(`Failed to clock out. Reason: ${error.message}`);
			});
	};

	return (
		<LoadingButton
			size="large"
			color="error"
			variant="contained"
			fullWidth
			onClick={handleClockOut}
			loading={isClockingOut}
			disabled={isClockingOut}
		>
			{t('Clock out')}
		</LoadingButton>
	);
};

const ReportAbsentButton = ({ disabled }) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	return (
		<>
			<Button
				size="large"
				variant="contained"
				color="secondary"
				onClick={() => setOpen(true)}
				disabled={disabled}
			>
				{t('Report Absent')}
			</Button>
			<ReportAbsentDialog open={open} setOpen={setOpen} />
		</>
	);
};

const TimeClockPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { user, timeClock, isTimeRecordDialogOpen, selectedTimeRecordId } = useSelector((state) => state.employee);
	const {
		isLoading,
		isActive,
		timeClockedIn,
		timeClockedOut,
		durationMinutes,
	} = timeClock;

	const { data: timeRecords, isFetching: isLoadingTimeRecords } =
		useGetTimeRecordsQuery(
			{
				employeeId: user.id,
				startDate: dayjs().subtract(7, 'day').toISOString(),
				endDate: dayjs().toISOString()
			},
			{ skip: !user.id }
		);

	const timeRecord = timeRecords?.find((record) => record.id === selectedTimeRecordId);

	return (
		<Container maxWidth="md">
			<Grid container direction="column" spacing={2}>
				<Grid item xs={8}>
					<Typography
						variant="h1"
						gutterBottom
						sx={{ fontWeight: 'bold' }}
					>
						{t('Time Clock')}
					</Typography>
					<Box>
						<CurrentTime />
						{isLoading ? (
							<Skeleton
								variant="text"
								sx={{ fontSize: '1rem' }}
							/>
						) : (
							<Box
								sx={{
									display: 'flex',
									gap: 1,
									color: 'text.secondary',
									alignItems: 'center',
								}}
							>
								{isActive ? (
									<>
										<Typography variant="h6">
											🟢{`${t('Clocked in')}:`}
										</Typography>
										<Typography
											variant="h6"
											sx={{ textTransform: 'capitalize' }}
										>
											{timeClockedIn
												? dayjs(timeClockedIn).format(
														'ddd DD MMM HH:mm',
												  )
												: ''}
										</Typography>
									</>
								) : (
									<>
										<Typography variant="h6">
											🔴{`${t('Clocked out')}:`}
										</Typography>
										<Typography
											variant="h6"
											sx={{ textTransform: 'capitalize' }}
										>
											{timeClockedOut
												? dayjs(timeClockedOut).format(
														'ddd DD MMM HH:mm',
												  )
												: ''}
										</Typography>
										{durationMinutes && (
											<Typography variant="h6">
												{dayjs
													.duration(
														durationMinutes,
														'minutes',
													)
													.asHours()
													.toFixed(2)}{' '}
												{t('hours').toLowerCase()}
											</Typography>
										)}
									</>
								)}
							</Box>
						)}
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: 1,
								py: 4,
							}}
						>
							{isLoading ? (
								<>
									{/* Skeleton for ClockIn/ClockOut Button */}
									<Skeleton variant="rectangular" width='100%' height={40} />
									{/* Skeleton for ReportAbsentButton */}
									<Skeleton variant="rectangular" width='100%' height={40} />
								</>
							) : (
								<>
									{!isActive ? <ClockInButton /> : <ClockOutButton />}
									<ReportAbsentButton disabled={isActive || isLoading} />
								</>
							)}
						</Box>
					</Box>
				</Grid>
				<Grid item xs={1}>
					<Typography variant="h4" gutterBottom>
						{t('Past7TimeRecords')}
					</Typography>
					{isLoadingTimeRecords ? (
						<Skeleton variant="rectangular" height={400} />
					) : (
						<TimeRecordList timeRecords={timeRecords || isLoading} />
					)}
				</Grid>
				<Grid item xs={1}>
					<Typography variant="h4" gutterBottom>
						{t('payPeriodSummary.TimeTotalLast2Months')}
					</Typography>
					<PayPeriodSummaryTable />
				</Grid>
			</Grid>
			<TimeRecordDialog
				timeRecord={timeRecord}
				isOpen={isTimeRecordDialogOpen}
				onClose={() => dispatch(employeeActions.closeTimeRecordDialog())}
			/>
		</Container>
	);
};

export default TimeClockPage;
